@font-face {
  font-family: 'Romanesco';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/Romanesco.ttf');
}

.scrollFont {
  font-family: 'Romanesco', cursive;
}
