::-webkit-scrollbar {
  display: none;
}

.slick-center img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 767px) {
  .slick-center img {
    transform: scale(1.45, 1.45);
  }
  .carousel-image {
    transform: scale(1.3, 1.3);
  }
}

@media screen and (min-width: 768px) {
  .slick-center img {
    transform: scale(1.25, 1.25);
  }
}

.slick-carousel {
  width: 100%;
}

.slick-slide {
  outline: none;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  padding-top: 8vh;

  @media screen and (max-width: 768px) {
    padding-top: 1%;
  }

  > div {
    margin: 0 2.5vw;

    @media screen and (max-width: 768px) {
      margin: 0 10vw;
    }
  }
}

.slick-list {
  margin: 0 -2.5vw;

  @media screen and (max-width: 768px) {
    margin: 0 -10vw;
  }
}
